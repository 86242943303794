<template>
  <GlobalWindow width="100%" :title="title" :visible.sync="visible" :withFooter="false">
    <div class="doc-wrapper">
      <div class="header">
        <el-form inline :model="form" ref="form" :show-message="false" :inline-message="true">
          <el-form-item class="bfont" label="Access Key" prop="appName">
            <el-input class="form-input" readonly v-model="form.ak" v-trim />
          </el-form-item>
          <el-form-item class="bfont" label="Secret key" prop="appDesc">
            <el-input class="form-input" readonly v-model="form.sk" v-trim>
              <el-button @click="refreshSk" slot="append" icon="el-icon-refresh"></el-button>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="content">
        <SearchDocument v-if="form.appType === 'search'" v-model="form"/>
        <ScreenshotDocument v-if="form.appType === 'screenshot'" v-model="form"/>
      </div>
    </div>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { refreshSk } from '@/api/apps/agentApps'
import { loadSysConfig } from '@/api/system/config'
import SearchDocument from './SearchDocument.vue'
import ScreenshotDocument from './ScreenshotDocument.vue'
export default {
  name: 'OperaAgentAppsWindow',
  extends: BaseOpera,
  components: { GlobalWindow, SearchDocument, ScreenshotDocument },
  data () {
    return {
      sysConf: {},
      docs: {
        activate: 'baidu_search'
      },
      // 表单数据
      form: {
        id: null,
        ak: '',
        sk: '',
        appType: ''
      }
    }
  },
  methods: {
    refreshSk () {
      refreshSk(this.form).then(res => {
        this.form.sk = res
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    async loadSysConf () {
      this.sysConf = await loadSysConfig()
    }
  },
  mounted () {
    this.loadSysConf()
  },
  created () {
    this.config({
      api: '/apps/agentApps',
      'field.id': 'id'
    })
  }
}
</script>

<style lang="scss" scoped>
.doc-wrapper {
  height: 100% !important;
  .header {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    .form-input {
      width: 400px !important;
    }
  }
  .content {
    height: calc(100% - 38px);
    .title-wrapper {
      padding-left: 10px;
      padding-right: 10px;
    }
    .doc-icon {
      width: 14px;
      display: inline-block;
      vertical-align: middle;
      padding-right: 2px;
    }
    .doc-title {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
<style lang="scss">
.doc-wrapper {
  .el-form-item {
    margin-bottom: 0 !important;
  }
}
</style>
