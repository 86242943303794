<template>
  <div class="doc-body-wrapper">
    <div class="title bfont">Screenshot API Documentation</div>
    <div class="desc">
      <el-tag>/screenshot</el-tag>
      <span> The API interface provides screenshot results that are largely consistent with the webpage display in the
        browser.</span>
    </div>
    <el-divider></el-divider>
    <el-card size="small" shadow="never" body-style="padding:10px; ">
      <div slot="header" class="clearfix">
        <span class="bfont">API</span>
      </div>
      <el-tag>POST</el-tag> <span>{{ sysConf.url }}/api/screenshot</span>
    </el-card>
    <el-card class="space-y-2" size="small" shadow="never" body-style="padding:10px;">
      <div slot="header" class="clearfix">
        <span class="bfont">API Parameters</span>
      </div>
      <el-row>
        <el-col :span="4">
          <el-tag>url</el-tag>
        </el-col>
        <el-col :span="8">
          <el-tag type="danger">Required</el-tag>
        </el-col>
        <el-col :span="12">
          The url of page you want to screenshot.
        </el-col>
      </el-row>
    </el-card>
    <el-card class="space-y-2" size="small" shadow="never" body-style="padding:10px;">
      <div slot="header" class="clearfix">
        <span class="bfont">Example of a request</span>
      </div>
      <code>
          curl --location --request POST '{{ sysConf.url }}/api/screenshot' \
          <br/>
          --header 'ak: {{ ak }}' \
          <br/>
          --header 'sk: {{ sk }}' \
          <br/>
          --header 'Content-Type: application/json' \
          <br/>--data-raw '{"url": "https://baijiahao.baidu.com/s?id=1782583073719953533"}'
        </code>
    </el-card>
    <el-card class="space-y-2" size="small" shadow="never" body-style="padding:10px;">
      <div slot="header" class="clearfix">
        <span class="bfont">API Results</span>
      </div>
      <JsonViewer :expand-depth=5 copyable :value="resText" />
    </el-card>
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'

export default {
  name: 'Screenshot',
  components: { JsonViewer },
  props: {
    ak: {
      type: String,
      default: function () {
        return ''
      }
    },
    sk: {
      type: String,
      default: function () {
        return ''
      }
    },
    sysConf: {
      type: Object,
      default: function () {
        return {
          sysName: 'AgentBrowser',
          url: '/'
        }
      }
    }
  },
  data () {
    return {
      resText: {
        code: 200,
        success: true,
        message: 'success',
        data: '/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAi3B4ADASIAAhEBAxEB/8QAHwABAAAGAwEBAAAAAAAAAAAAAAQFBgcICQIDCgEL/8QAhxAAAAYCAgADBAMICQwKBwExAQIDBAUGAAcIERITIQkUMUEVIlEWFzJUYXGBkSNSlKGx0dTV8AoYNUJXdJWWs8HS0xkkM1NVVmeTo+ElNFhiZJek1tfj5SZDRHJzgoSSp7LxmKLDGic4OUhZZXV4g6W0trfC4ig3RWN3h7jH2EZHxdk6hpn/xAAeAQEAAgMBAQEBAQAAAAAAAAAAAgMBBAUGBwgJCv/EAGgRAAIBAwMCAwQGBgUGCAkAGwECAwAEEQUSIRMxBkFRFCJhcSMygZGh8AcVUrHB0SRCkuHxCBYzU5OiFzRDYnKCldIYJVRVVrKz09REV2Nzg5SWwtUmNUWjw+MJNmQ4ZWeEhYa0tbb/2gAMAwEAAhEDEQA/APfxjGMUpjGMUpjGMUpj......',
        exception: null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.doc-body-wrapper {
  .title {
    font-size: 18px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .desc {
    font-size: 14px;
    padding-top: 10px;
  }

  .space-y-2 {
    margin-top: 0.5rem;
  }
}
</style>
<style lang="scss">
.doc-body-wrapper {
  .el-card__header {
    padding: 10px;
  }
}
</style>
