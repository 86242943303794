<template>
  <el-container class="main-wrapper">
    <el-header class="header-wrapper">
      <div class="header-content">
        <div class="logo">
          <span class="bfont">{{ sysNeme }}</span>
          <img class="beta" src="@/assets/icons/ext/beta.svg">
        </div>
        <div class="menus">
        </div>
        <div class="login">
          <div v-if="user.username">
            <el-button size="medium" type="primary" plain round @click="handleToDahboard">
              <!-- 管理中心 -->
              <span class="bfont">Dashboard</span>
            </el-button>
          </div>
          <div v-else>
            <el-button size="medium" type="primary" plain round @click="handleToLogin">
              <!-- 登录 -->
              <span class="bfont">Sign In</span>
            </el-button>
            <el-button size="medium" type="primary" round @click="handleToRegist">
              <!-- 注册 -->
              <span class="bfont">Register</span>
            </el-button>
          </div>
        </div>
      </div>
    </el-header>
    <el-main class="main-container">
      <!-- <div class="title bfont">
        {{ sysNeme }}
      </div> -->
      <div class="quick-start">
        <el-button size="huge" type="warning" plain round @click="handleToLogin">
          <span class="bfont">Get started today <i class="el-icon-right"></i></span>
        </el-button>
      </div>
      <div class="main-container-one">
        <div>
          <div class="title bfont">
            Search Api
          </div>
          <div class="subtitle bfont">
            <!-- 高效、便捷、全面的搜索引擎数据获取API服务 -->
            Efficient, convenient, and comprehensive API service <br> for search engine (google / Bing / Baidu) data
            retrieval.
          </div>
          <div class="image">
            <img src="@/assets/images/code.png">
          </div>
        </div>

        <!-- line -->
        <div class="split-line">
          <div style="text-align: center;">
            <i class="el-icon-bangzhu" style="font-size: 20px; color: aliceblue; position: relative; bottom: 8px;"></i>
          </div>
        </div>
        <div>
          <div class="title bfont">
            Screenshot Api
          </div>
          <div class="subtitle bfont">
            <!-- 高效、便捷、全面 的网页截图 API 服务 -->
            Efficient, convenient, and comprehensive <br> web page screenshot API service.
          </div>
          <div class="image image-screenshot">
            <img src="@/assets/images/code2.png">
          </div>
        </div>
        <!-- line -->
        <div class="split-line">
          <div style="text-align: center;">
            <i class="el-icon-bangzhu" style="font-size: 20px; color: aliceblue; position: relative; bottom: 8px;"></i>
          </div>
        </div>
        <div>
          <div class="title bfont">
            CONCAT US
          </div>
          <div class="subtitle bfont">
            <!-- 高效、便捷、全面 的网页截图 API 服务 -->
            We warmly welcome your questions, requests, special needs, or just a friendly hello.
          </div>
          <div class="concat-box">
            <a class="concat-big-button" href="mailto: joinwenb@gmail.com">
              <img src="@/assets/icons/ext/email.svg" />
            </a>
            <a class="concat-big-button" href="https://github.com/ailab2/ExploreSerp/issues/new" target="_blank">
              <img src="@/assets/icons/ext/github-fill.svg" />
            </a>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer class="footer-container" height="110px">
      <div style="height: 20px;"></div>
      <div class="copyright">
        <p>&copy; 2023 Zhangyw. All rights reserved.</p>
      </div>
      <div class="concat">
        <!-- 横向排列的列表，分别为：QQ群、微信群、微信公众号、微信小程序、支付宝、微信支付 -->
        <ul>
          <li><span>CONTACT US</span></li>
          <li><a href="mailto: joinwenb@gmail.com"> <i class="el-icon-message"></i> Email</a></li>
          <li><a href="https://github.com/ailab2/ExploreSerp/issues/new" target="_blank"> <i class="el-icon-box"></i>
              Github</a> </li>
        </ul>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import { getUserInfo } from '@/api/system/common'
import { loadSysConfig } from '@/api/system/config'
import { mapState } from 'vuex'

export default {
  name: 'main',
  data () {
    return {
      sysNeme: '',
      user: {}
    }
  },
  computed: {
    ...mapState(['homePage'])
  },
  methods: {
    async loadSysConf () {
      const sysConf = await loadSysConfig()
      this.sysNeme = sysConf.sysName || 'AgentBrowser'
      document.title = this.sysNeme
    },
    handleToDahboard () {
      this.$router.push({ name: 'index' })
    },
    handleToLogin () {
      this.$router.push({ name: 'login' })
    },
    handleToRegist () {
      this.$router.push({ name: 'signup' })
    },
    handleToMain () {
      this.$router.push('/')
    },
    async checkLogin () {
      try {
        const user = await getUserInfo()
        this.user = user
      } catch (error) {
        this.user = {}
      }
    }
  },
  mounted () {
    this.checkLogin()
    this.loadSysConf()
  }
}
</script>

<style lang="scss" scoped>
.main-wrapper {
  height: 100%;

  .header-wrapper {
    background-color: #2E68EC;
    text-align: center;
    // background-image: linear-gradient(to left, #fff1eb 0%, #ace0f9 100%);

    // background-image: linear-gradient(35deg, #f5f7fa 0%, #c3cfe2 100%);
    color: #fff;

    .header-content {
      display: inline-block;
      width: 1000px;
      text-align: left;

      .logo {
        display: inline-block;
        vertical-align: middle;
        font-size: 26px;
        font-weight: bold;
        line-height: 60px;
        width: 250px;

        .beta {
          width: 40px;
          vertical-align: middle;
          padding-left: 10px;
        }
      }

      .menus {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 460px);
      }

      .login {
        display: inline-block;
        vertical-align: middle;
        width: 210px;
      }
    }
  }

  .main-container {
    background: rgb(11, 87, 205);
    background: linear-gradient(180deg, rgba(11, 87, 205, 1) 0%, rgba(10, 145, 238, 1) 42%, rgba(9, 192, 230, 1) 100%);
    height: 100%;
    padding: 0;
    padding-bottom: 80px;
    .quick-start {
      text-align: center;
      padding-top: 100px;
    }

    // .main-container-one {
    //   background: linear-gradient(180deg, rgb(229, 87, 87) 0%, rgb(240, 225, 91) 42%, rgba(9, 192, 230, 1) 100%);
    // }

    // overflow: hidden;
    /* 自定义滚动条样式 */
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: linear-gradient(180deg, rgba(11, 87, 205, 1) 0%, rgba(10, 145, 238, 1) 42%, rgba(9, 192, 230, 1) 100%);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(10, 145, 238, 1);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgb(11, 87, 205);
    }

    .title {
      font-size: 100px;
      font-weight: bold;
      text-align: center;
      padding: 40px 0 40px 0;
      color: #fff;
    }

    .subtitle {
      text-align: center;
      color: #fff;
      font-size: 24px;
    }

    .image {
      text-align: center;

      img {
        width: 900px;
      }
    }

    .concat-box {
      text-align: center;
      padding-top: 40px;

      .concat-big-button {
        display: inline-block;
        padding: 20px;
        width: 80px;
        height: 80px;
        border-radius: 30%;
        background-color: #ffffff82;
        margin: 0 10px;
        color: #4979d7;
        transition: 0.6s;

        img {
          width: 80px;
          height: 80px;
        }

        vertical-align: middle;

        &:hover {
          background-color: #fff;
          transition: 0.6s;
        }
      }
    }

    .split-line {
      padding: 100px 0;

      div {
        height: 2px;
        background: rgba(135, 190, 245, 0.571);
        margin: 0 auto;
      }
    }
  }

  .footer-container {
    background: rgba(9, 192, 230, 1);

    .copyright {
      font-size: 14px;
      font-weight: bold;
      font-family: 'Avenir', Helvetica, Arial, sans-serif;
      color: #fff;
      text-align: center;
    }

    .concat {
      ul {
        display: flex;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin: 0 10px;
          font-size: 14px;
          font-weight: bold;
          font-family: 'Avenir', Helvetica, Arial, sans-serif;
          color: #fff;

          a {
            display: inline-block;
            background-color: #fbfdff;
            padding: 1px 12px;
            border-radius: 10px;
            // no text underline
            text-decoration: none;
            color: #4979d7;

            &:hover {
              background-color: #4979d7;
              color: #f8faff;
              transition: 0.6s;
            }
          }
        }
      }
    }
  }
}
</style>
