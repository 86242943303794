<template>
  <el-container class="all-container">
    <el-aside class="left-container" width="400px">
      <div>
        <el-alert type="success" title="Domain" description="After a domain name is added to the whitelist, all addresses under that domain name can obtain screenshot permissions." />
        <div style="height: 10px"></div>
        <el-alert type="success" title="URL" description="After a URL is configured, that URL can have screenshot permissions." />
        <div style="height: 10px"></div>
        <el-input placeholder="google.com or https://google.com/hello.html" v-model="form.urlPattern" class="input-with-select" size="mini">
          <el-button slot="append" type="primary" icon="el-icon-plus" @click="handerAddUrlPattern">ADD</el-button>
        </el-input>
      </div>
      <div style="height: 10px"></div>
      <div>
        <UrlList ref="urlListRefs" v-model="form" />
      </div>
    </el-aside>
    <el-main>
      <Screenshot v-model="value" :sysConf="sysConf" :ak="value.ak" :sk="value.sk"/>
    </el-main>
  </el-container>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import { loadSysConfig } from '@/api/system/config'
import UrlList from './docs/urllist/index.vue'
import { create } from '@/api/apps/appScreenshotWhitelist'
import Screenshot from './docs/screenshot/index.vue'

export default {
  name: 'OperaAgentAppsWindow',
  extends: BaseOpera,
  components: { UrlList, Screenshot },
  data () {
    return {
      sysConf: {},
      docs: {
        activate: 'baidu_search'
      },
      form: {
        appId: this.value.id,
        urlPattern: ''
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          ak: '',
          sk: '',
          id: ''
        }
      }
    }
  },
  created () {
    this.config({
      api: '/apps/appScreenshotWhitelist',
      'field.id': 'id'
    })
  },
  methods: {
    async loadSysConf () {
      this.sysConf = await loadSysConfig()
    },
    handerAddUrlPattern () {
      create(this.form).then(res => {
        this.$refs.urlListRefs.loadTableData()
        this.form.urlPattern = ''
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    }
  },
  mounted () {
    this.loadSysConf()
  }
}
</script>

<style scoped>
.all-container {
  padding: 0;
  height: 100% !important;
}

.left-container {
  padding: 10px;
  height: 100%;
  border-right: 1px solid #ebe9e9;
  /* background-color: antiquewhite; */
}
</style>
