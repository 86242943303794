<template>
  <el-tabs v-model="docs.activate" type="border-card">
    <el-tab-pane name="baidu_search">
      <span class="title-wrapper" slot="label"> <img class="doc-icon" src="@/assets/icons/ext/baidu.svg"> <span
          class="doc-title">Baidu</span></span>
      <BaiduSearch :ak="value.ak" :sk="value.sk" :sysConf="sysConf" />
    </el-tab-pane>
    <el-tab-pane label="Bing" name="bing_search">
      <span class="title-wrapper" slot="label"> <img class="doc-icon" src="@/assets/icons/ext/bing.svg"> <span
          class="doc-title">Bing</span></span>
      <BingSearch :ak="value.ak" :sk="value.sk" :sysConf="sysConf" />
    </el-tab-pane>
    <el-tab-pane label="Google" name="google_search">
      <span class="title-wrapper" slot="label"> <img class="doc-icon" src="@/assets/icons/ext/google.svg"> <span
          class="doc-title">Google</span></span>
      <GoogleSearch :ak="value.ak" :sk="value.sk" :sysConf="sysConf" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import BaiduSearch from './docs/baidusearch/index.vue'
import GoogleSearch from './docs/googlesearch/index.vue'
import BingSearch from './docs/bingsearch/index.vue'
import { loadSysConfig } from '@/api/system/config'
export default {
  name: 'OperaAgentAppsWindow',
  components: { BaiduSearch, GoogleSearch, BingSearch },
  data () {
    return {
      sysConf: {},
      docs: {
        activate: 'baidu_search'
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          ak: '',
          sk: ''
        }
      }
    }
  },
  methods: {
    async loadSysConf () {
      this.sysConf = await loadSysConfig()
    }
  },
  mounted () {
    this.loadSysConf()
  }
}
</script>

<style lang="scss" scoped>
.doc-wrapper {
  .header {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;

    .form-input {
      width: 400px !important;
    }
  }

  .content {
    .title-wrapper {
      padding-left: 10px;
      padding-right: 10px;
    }

    .doc-icon {
      width: 14px;
      display: inline-block;
      vertical-align: middle;
      padding-right: 2px;
    }

    .doc-title {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
<style lang="scss">
.doc-wrapper {
  .el-form-item {
    margin-bottom: 0 !important;
  }
}</style>
