<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    confirmText="Confirm"
    cancelText="Cancel"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="Name" prop="appName" required>
        <el-input v-model="form.appName" placeholder="Name" v-trim/>
      </el-form-item>
      <el-form-item label="Type" prop="appType" required>
        <el-select :disabled="isEdit" v-model="form.appType" placeholder="Type" v-trim>
          <el-option label="Search Application" value="search"></el-option>
          <el-option label="Screenshot Application" value="screenshot"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Description" prop="appDesc">
        <el-input type="textarea" v-model="form.appDesc" placeholder="Description" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaAgentAppsWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        appName: '',
        appDesc: '',
        appType: 'search'
      },
      // 验证规则
      rules: {
        appName: [
          { required: true, message: 'app name is required' }
        ],
        createTime: [
          { required: true, message: '请选择创建时间' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/apps/agentApps',
      'field.id': 'id'
    })
  }
}
</script>
