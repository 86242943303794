import { render, staticRenderFns } from "./PermissionConfigWindow.vue?vue&type=template&id=440f0058&scoped=true"
import script from "./PermissionConfigWindow.vue?vue&type=script&lang=js"
export * from "./PermissionConfigWindow.vue?vue&type=script&lang=js"
import style0 from "./PermissionConfigWindow.vue?vue&type=style&index=0&id=440f0058&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "440f0058",
  null
  
)

export default component.exports