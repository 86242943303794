<template>
  <div>
    <el-radio-group v-model="showModel">
      <el-radio-button label="json"></el-radio-button>
      <el-radio-button label="preview"></el-radio-button>
    </el-radio-group>
    <JsonViewer v-if="showModel === 'json'" :expand-depth=5 copyable :value="model" />
    <div v-else class="detail-wrapper space-y-2">
      <el-collapse>
        <el-collapse-item v-for="(item, index) in model" :key="item.id" :name="item.id">
          <template slot="title">
            <span><el-tag>{{ index }}</el-tag> {{ item.url }}</span>
          </template>
          <el-descriptions size="medium" :column="2" border>
            <el-descriptions-item label="date">{{ item.date }}</el-descriptions-item>
            <el-descriptions-item label="successful">{{ item.successful }}</el-descriptions-item>
            <el-descriptions-item label="queued">{{ item.queued }}</el-descriptions-item>
            <el-descriptions-item label="rejected">{{ item.rejected }}</el-descriptions-item>
            <el-descriptions-item label="unhealthy">{{ item.unhealthy }}</el-descriptions-item>
            <el-descriptions-item label="memory">{{ item.memory }}</el-descriptions-item>
            <el-descriptions-item label="cpu">{{ item.cpu }}</el-descriptions-item>
            <el-descriptions-item label="timedout">{{ item.timedout }}</el-descriptions-item>
            <el-descriptions-item label="totalTime">{{ item.totalTime }}</el-descriptions-item>
            <el-descriptions-item label="meanTime">{{ item.meanTime }}</el-descriptions-item>
            <el-descriptions-item label="maxTime">{{ item.maxTime }}</el-descriptions-item>
            <el-descriptions-item label="minTime">{{ item.minTime }}</el-descriptions-item>
            <el-descriptions-item label="maxConcurrent">{{ item.maxConcurrent }}</el-descriptions-item>
            <el-descriptions-item label="sessionTimes">{{ item.sessionTimes }}</el-descriptions-item>
          </el-descriptions>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import { metrics } from '@/api/browser/agentBrowser.js'
import JsonViewer from 'vue-json-viewer'

export default {
  name: 'MetricsDetail',
  components: { JsonViewer },
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      showModel: 'json',
      // configText: '',
      model: [
        {
          date: 0,
          successful: 0,
          queued: 0,
          rejected: 0,
          unhealthy: 0,
          memory: 0,
          cpu: 0,
          timedout: 0,
          totalTime: 0,
          meanTime: 0,
          maxTime: 0,
          minTime: 0,
          maxConcurrent: 0,
          sessionTimes: [0]
        }
      ]
    }
  },
  methods: {
    async load (id) {
      try {
        const res = await metrics(id)
        // this.configText = res
        this.model = res
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-wrapper {
  padding: 20px 0;
}
</style>
