import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.get(`/agentApps/${data.appId}/urlWhitelist`)
}

// 创建
export function create (data) {
  return request.post(`/agentApps/${data.appId}/urlWhitelist`, data)
}

// 删除
export function deleteOne (data) {
  return request.delete(`/agentApps/${data.appId}/urlWhitelist/${data.id}`)
}
