<template>
  <TableLayout :permissions="['agentbrowser:agentbrowser:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="60px" inline>
      <el-form-item label="名称" prop="name">
        <el-input v-model="searchForm.name" placeholder="请输入名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="创建者" prop="creatorName">
        <el-input v-model="searchForm.creatorName" placeholder="请输入创建者" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['agentbrowser:agentbrowser:create', 'agentbrowser:agentbrowser:delete']">
        <li><el-button type="primary" @click="$refs.operaAgentBrowserWindow.open('新建无头浏览器配置')" icon="el-icon-plus" v-permissions="['agentbrowser:agentbrowser:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['agentbrowser:agentbrowser:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name" label="名称" width="100px">
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaAgentBrowserDetail.open('详情', row)" icon="el-icon-link">{{ row.name }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="url" label="地址" min-width="100px"></el-table-column>
        <el-table-column prop="token" label="令牌" width="240px">
          <template slot-scope="{row}">
            <el-tag>{{ row.token }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="80px">
          <template slot-scope="{row}">
            <div>
              <el-tag type="danger" v-if="row.status === 0">下线</el-tag>
            <el-tag type="danger" v-if="row.status === 1">上线</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="调用次数" width="80px">
          <template slot-scope="{row}">
            <div>
              <el-tag type="danger" >{{ row.callNum }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="creatorName" label="创建者" width="100px"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['agentbrowser:agentbrowser:update', 'agentbrowser:agentbrowser:delete'])"
          label="操作"
          width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaAgentBrowserWindow.open('编辑无头浏览器配置', row)" icon="el-icon-edit" v-permissions="['agentbrowser:agentbrowser:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['agentbrowser:agentbrowser:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaAgentBrowserWindow ref="operaAgentBrowserWindow" @success="handlePageChange"/>
    <OperaAgentBrowserDetail ref="operaAgentBrowserDetail"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaAgentBrowserWindow from './components/OperaAgentBrowserWindow'
import OperaAgentBrowserDetail from './components/OperaAgentBrowserDetail'
export default {
  name: 'AgentBrowser',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaAgentBrowserWindow, OperaAgentBrowserDetail },
  data () {
    return {
      // 搜索
      searchForm: {
        name: '',
        creatorName: ''
      }
    }
  },
  created () {
    this.config({
      module: '无头浏览器配置',
      api: '/browser/agentBrowser',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
