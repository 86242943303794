<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    width="80%"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="规则组" prop="ruleGroup" required>
        <DictDataSelect
          v-model="form.ruleGroup"
          code="rule_group"
          plac
          eholder="请输入生效请输入规则组状态"
          clearable
        />
      </el-form-item>
      <el-form-item label="生效状态" prop="ruleEnable" required>
        <DictDataSelect
          v-model="form.ruleEnable"
          code="rule_encode"
          placeholder="请输入生效状态"
          clearable
        />
      </el-form-item>
      <el-form-item label="解析规则" prop="ruleXml">
        <codemirror v-model="form.ruleXml" :options="options"></codemirror>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import DictDataSelect from '@/components/system/dict/DictDataSelect'
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
// 主题
import 'codemirror/theme/3024-day.css' // 引入主题样式，根据设置的theme的主题引入
import 'codemirror/theme/ayu-mirage.css'
import 'codemirror/theme/monokai.css'
import 'codemirror/theme/rubyblue.css'
import 'codemirror/mode/htmlmixed/htmlmixed.js'
import 'codemirror/mode/xml/xml.js'

export default {
  name: 'OperaAgentWebsiteParserRuleWindow',
  extends: BaseOpera,
  components: { GlobalWindow, DictDataSelect, codemirror },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        ruleXml: '',
        ruleEnable: 'enable',
        ruleGroup: '',
        code: ''
      },
      // 验证规则
      rules: {
        ruleEnable: [
          { required: true, message: '请输入生效状态 0-生效 1-不生效' }
        ],
        ruleGroup: [{ required: true, message: '请输入规则组' }],
        createAt: [{ required: true, message: '请选择' }]
      },
      options: {
        line: true,
        theme: 'monokai', // 主题
        defaultTextHeight: 20,
        fontSize: 16,
        tabSize: 4, // 制表符的宽度
        indentUnit: 2, // 一个块应该缩进多少个空格（无论这在编辑语言中意味着什么）。默认值为 2。
        firstLineNumber: 1, // 从哪个数字开始计算行数。默认值为 1。
        readOnly: false, // 只读
        autorefresh: true,
        smartIndent: true, // 上下文缩进
        lineNumbers: true, // 是否显示行号
        styleActiveLine: true, // 高亮选中行
        viewportMargin: Infinity, // 处理高度自适应时搭配使用
        showCursorWhenSelecting: true, // 当选择处于活动状态时是否应绘制游标
        mode: 'application/xml'
      }
    }
  },
  created () {
    this.config({
      api: '/rules/agentWebsiteParserRule',
      'field.id': 'id'
    })
  }
}
</script>
<style>
.CodeMirror {
  line-height: 1.5;
}
</style>
