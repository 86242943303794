import { render, staticRenderFns } from "./DictDataSelect.vue?vue&type=template&id=88dfbcb2&scoped=true"
import script from "./DictDataSelect.vue?vue&type=script&lang=js"
export * from "./DictDataSelect.vue?vue&type=script&lang=js"
import style0 from "./DictDataSelect.vue?vue&type=style&index=0&id=88dfbcb2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88dfbcb2",
  null
  
)

export default component.exports