<template>
  <el-table :data="tableData" style="width: 100%" size="mini" :show-header="false">
    <el-table-column label="地址">
      <template slot-scope="scope">
        <el-tag>{{ scope.row.urlPattern }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="40">
      <template slot-scope="scope">
        <i class="el-icon-delete" style="cursor: pointer;" @click="handlerDelete(scope.row)"></i>
        <!-- <el-button size="mini" type="danger" circle icon="el-icon-delete" /> -->
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { fetchList, deleteOne } from '@/api/apps/appScreenshotWhitelist'
export default {
  data () {
    return {
      tableData: [{
        urlPattern: 'www.baidu.com'
      }]
    }
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          appId: '',
          urlPattern: ''
        }
      }
    }
  },
  methods: {
    loadTableData () {
      fetchList({
        appId: this.value.appId
      }).then(res => {
        console.log(res)
        this.tableData = res
      }).catch(e => {
        this.$tip.apiFailed(e)
      })
    },
    handlerDelete (row) {
      this.$confirm('Are you sure you want to delete this record?', 'Confirm', {
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        deleteOne(row).then(() => {
          this.loadTableData()
          this.$tip.success('Delete successfully')
        }).catch(e => {
          this.$tip.apiFailed(e)
        })
      }).catch(() => {
        this.$tip.info('Canceled')
      })
    }
  },
  mounted () {
    this.loadTableData()
  }
}
</script>

<style scoped></style>
