<template>
  <div v-loading="loading">
    <el-form label-position="right" label-width="100px" :model="formData">
      <el-form-item label="协议">
        <el-input readonly v-model="formData.protocol"></el-input>
      </el-form-item>
      <el-form-item label="SMTP 主机">
        <el-input v-model="formData.smtpHost"></el-input>
      </el-form-item>
      <el-form-item label="端口">
        <el-input v-model="formData.port"></el-input>
      </el-form-item>
      <el-form-item label="用户名">
        <el-input v-model="formData.username"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="formData.password"></el-input>
      </el-form-item>
      <el-form-item label="注册消息模板">
        <el-input type="textarea" rows="10" v-model="formData.signupMsgTemplate"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSave">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { loadEmailConfig, saveEmailConfig } from '@/api/system/config'

export default {
  data () {
    return {
      loading: false,
      formData: {
        protocol: 'smtps',
        smtpHost: '',
        port: '',
        username: '',
        password: '',
        signupMsgTemplate: ''
      }
    }
  },
  methods: {
    __check () {
      if (this.formData.protocol.trim() === '') {
        this.$tip.error('请输入协议')
        return false
      }
      if (this.formData.smtpHost.trim() === '') {
        this.$tip.error('请输入SMTP 主机')
        return false
      }
      if (this.formData.port === null || this.formData.port === undefined) {
        this.$tip.error('请输入端口')
        return false
      }
      if (this.formData.username.trim() === '') {
        this.$tip.error('请输入用户名')
        return false
      }
      if (this.formData.password.trim() === '') {
        this.$tip.error('请输入密码')
        return false
      }
      if (this.formData.signupMsgTemplate.trim() === '') {
        this.$tip.error('请输入注册模板')
        return false
      }
      return true
    },
    async handleSave () {
      if (!this.__check()) {
        return
      }
      this.loading = true
      try {
        await saveEmailConfig(this.formData)
        this.$tip.success('保存成功')
      } catch (error) {
        console.error(error)
        this.$tip.apiFailed(error)
      } finally {
        this.loading = false
      }
    },
    async loadConfig () {
      this.loading = true
      try {
        const data = await loadEmailConfig()
        this.formData = {
          protocol: data.protocol || 'smtps',
          smtpHost: data.smtpHost || '',
          port: data.port || '',
          username: data.username || '',
          password: data.password || '',
          signupMsgTemplate: data.signupMsgTemplate || ''
        }
      } catch (error) {
        console.error(error)
        this.$tip.apiFailed(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.loadConfig()
  }
}
</script>

<style lang="scss" scoped></style>
