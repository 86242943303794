import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/agentBrowser/page', data, {
    trim: true
  })
}

// 创建
export function create (data) {
  return request.post('/agentBrowser/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/agentBrowser/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/agentBrowser/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/agentBrowser/delete/batch', {
    params: {
      ids
    }
  })
}

export function configs (id) {
  return request.get(`/agentBrowser/${id}/configs`)
}

export function sessions (id) {
  return request.get(`/agentBrowser/${id}/sessions`)
}

export function pressure (id) {
  return request.get(`/agentBrowser/${id}/pressure`)
}

export function metrics (id) {
  return request.get(`/agentBrowser/${id}/metrics`)
}

export function killALl (id) {
  return request.get(`/agentBrowser/${id}/killAll`)
}
