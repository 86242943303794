<template>
  <el-select
    class="dict-select"
    :class="{select__block: !inline}"
    :value="value"
    :placeholder="placeholder"
    :clearable="clearable"
    :disabled="disabled"
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
  >
    <el-option v-for="data in dataList" :key="data.id" :value="data.code" :label="data.label"/>
  </el-select>
</template>

<script>
import { findByDictCode } from '@/api/system/dictData'
export default {
  name: 'DictDataSelect',
  props: {
    // 字典编码
    code: {
      type: String,
      required: true
    },
    value: {},
    placeholder: {
      default: '请选择'
    },
    inline: {
      default: true
    },
    disabled: {},
    clearable: {
      default: false
    }
  },
  data () {
    return {
      dataList: []
    }
  },
  created () {
    findByDictCode({
      dictCode: this.code
    })
      .cache()
      .then(data => {
        this.dataList = data
      })
  }
}
</script>
<style lang="scss" scoped>
.select__block {
  display: block;
}
</style>
