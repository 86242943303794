<template>
  <TableLayout :permissions="['agentapps:agentapps:query']">
    <!-- 搜索表单 -->
    <el-form
      ref="searchForm"
      slot="search-form"
      :model="searchForm"
      label-width="100px"
      inline
      class="bfont"
    >
      <el-form-item label="AppName" prop="appName">
        <el-input
          style="width: 300px;"
          v-model="searchForm.appName"
          placeholder="AppName"
          @keypress.enter.native="search"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="应用描述" prop="appDesc">
        <el-input
          v-model="searchForm.appDesc"
          placeholder="请输入应用描述"
          @keypress.enter.native="search"
        ></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="创建时间" prop="createTime">
        <el-date-picker
          v-model="searchForm.createTime"
          value-format="yyyy-MM-dd"
          placeholder="请输入创建时间"
          @change="search"
        />
      </el-form-item> -->
      <section>
        <el-button type="primary" @click="search">Search</el-button>
        <el-button @click="reset">Reset</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul
        class="toolbar"
        v-permissions="[
          'agentapps:agentapps:create',
          'agentapps:agentapps:delete',
        ]"
      >
        <li>
          <el-button
            type="primary"
            @click="$refs.operaAgentAppsWindow.open('新建应用')"
            icon="el-icon-plus"
            v-permissions="['agentapps:agentapps:create']"
            >
            <!-- 新建 -->
            <span class="bfont">New App</span>
            </el-button
          >
        </li>
        <!-- <li>
          <el-button
            @click="deleteByIdInBatch"
            icon="el-icon-delete"
            v-permissions="['agentapps:agentapps:delete']"
            >删除
            </el-button
          >
        </li> -->
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        empty-text="No Data"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          width="180px"
        ></el-table-column>
        <el-table-column
          prop="appName"
          label="NAME"
          width="200px"
        ></el-table-column>
        <el-table-column
          prop="appType"
          label="TYPE"
          width="200px"
        >
        <template slot-scope="{ row }">
          <el-tag>{{ row.appType }}</el-tag>
        </template>
      </el-table-column>
        <el-table-column
          prop="appDesc"
          label="INTRODUCTION"
          min-width="100px"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="CREATE TIME"
          width="160px"
        ></el-table-column>
        <el-table-column
          v-if="
            containPermissions([
              'agentapps:agentapps:update',
              'agentapps:agentapps:delete',
            ])
          "
          label="OPERATION"
          width="180"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              @click="$refs.operaAgentAppsSecureWindow.open('Api Document', row)"
              icon="el-icon-coin"
              v-permissions="['agentapps:agentapps:update']"
              >API</el-button
            >
            <el-button
              type="text"
              @click="$refs.operaAgentAppsWindow.open('Editing App', row)"
              icon="el-icon-edit"
              v-permissions="['agentapps:agentapps:update']"
              >Edit</el-button
            >
            <el-button
              type="text"
              @click="deleteById(row)"
              icon="el-icon-delete"
              v-permissions="['agentapps:agentapps:delete']"
              >Delete</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaAgentAppsWindow
      ref="operaAgentAppsWindow"
      @success="handlePageChange"
    />
    <OperaAgentAppsSecureWindow ref="operaAgentAppsSecureWindow" />
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaAgentAppsWindow from './components/OperaAgentAppsWindow'
import OperaAgentAppsSecureWindow from './components/OperaAgentAppsSecureWindow'
export default {
  name: 'AgentApps',
  extends: BaseTable,
  components: {
    TableLayout,
    Pagination,
    OperaAgentAppsWindow,
    OperaAgentAppsSecureWindow
  },
  data () {
    return {
      // 搜索
      searchForm: {
        appName: '',
        appDesc: '',
        createTime: ''
      }
    }
  },
  created () {
    this.config({
      module: 'App',
      configData: {
        // id字段
        'field.id': 'id',
        // 主字段
        'field.main': 'appName'
      },
      api: '/apps/agentApps',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
