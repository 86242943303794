<template>
  <GlobalWindow :title="title" :visible.sync="visible" :confirm-working="isWorking" @confirm="confirm" width="1200px" :withFooter="false">
    <el-tabs v-model="activeName" @tab-click="handleTabClick" >
      <el-tab-pane label="配置信息" name="config">
        <ConfigDetail ref="configDetailRef" v-model="form"/>
      </el-tab-pane>
    <el-tab-pane label="活跃会话" name="sessions">
      <SessionDetail ref="sessionDetailRef" v-model="form"/>
    </el-tab-pane>
    <el-tab-pane label="会话指标" name="metrics">
      <MetricsDetail ref="metricsDetailRef" v-model="form"/>
    </el-tab-pane>
    <el-tab-pane label="性能指标" name="pressure">
      <PressureDetail ref="pressureDetailRef" v-model="form"/>
    </el-tab-pane>
  </el-tabs>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import ConfigDetail from './details/ConfigDetail.vue'
import SessionDetail from './details/SessionDetail.vue'
import PressureDetail from './details/PressureDetail.vue'
import MetricsDetail from './details/MetricsDetail.vue'
export default {
  name: 'OperaAgentBrowserDetail',
  extends: BaseOpera,
  components: { GlobalWindow, ConfigDetail, SessionDetail, PressureDetail, MetricsDetail },
  data () {
    return {
      activeName: 'config',
      // 表单数据
      form: {
        id: null,
        name: '',
        url: '',
        token: '',
        status: ''
      },
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入名称' }
        ],
        url: [
          { required: true, message: '请输入地址' }
        ],
        token: [
          { required: true, message: '请输入令牌' }
        ]
      }
    }
  },
  methods: {
    handleTabClick (tab, event) {
      if (tab.name === 'sessions') {
        this.$nextTick(() => {
          this.$refs.sessionDetailRef.load(this.form.id)
        })
      } else if (tab.name === 'pressure') {
        this.$nextTick(() => {
          this.$refs.pressureDetailRef.load(this.form.id)
        })
      } else if (tab.name === 'metrics') {
        this.$nextTick(() => {
          this.$refs.metricsDetailRef.load(this.form.id)
        })
      } else if (tab.name === 'config') {
        this.$nextTick(() => {
          this.$refs.configDetailRef.load(this.form.id)
        })
      }
    },
    afterOpen () {
      this.$nextTick(() => {
        console.log(this.$refs.configDetailRef)
        this.$refs.configDetailRef.load(this.form.id)
      })
    }
  },
  created () {
    this.config({
      api: '/browser/agentBrowser',
      'field.id': 'id'
    })
  }
}
</script>
