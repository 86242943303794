<template>
  <div style="height: 100%;">
    <div class="regist-valid-wrapper" v-if="validState === 'doing'">
      <div class="msg">校验中...</div>
    </div>
    <div class="regist-valid-wrapper" v-if="validState === 'success'">
      <div class="msg">🎉 恭喜你，注册完成!</div>
      <div class="jump">跳转中... </div>
      <div class="jump">
        如果跳转失败，请点击 <span class="link" @click="handleToMain">链接</span> 手动跳转
      </div>
    </div>
    <div class="regist-valid-wrapper" v-if="validState === 'falied'">
      <div class="msg">🙃 抱歉，校验失败, 请重新前往<span class="link" @click="handleToRegist">注册</span>!</div>
    </div>
  </div>
</template>

<script>
import { signUpValid } from '@/api/system/common'
export default {
  data () {
    return {
      validState: 'doing'
    }
  },
  methods: {
    handleToMain () {
      this.$router.push({ name: 'main' })
    },
    handleToRegist () {
      this.$router.push({ name: 'signup' })
    },
    async checkSinup () {
      const sid = this.$route.query.sid
      try {
        await signUpValid({ sid: sid })
        setTimeout(() => {
          this.validState = 'success'
          setTimeout(() => {
            this.$router.push({ name: 'login' })
          }, 2000)
        }, 1000)
      } catch (error) {
        this.validState = 'falied'
      }
    }
  },
  mounted () {
    this.validState = 'doing'
    this.checkSinup()
  }
}
</script>

<style lang="scss" scoped>
.regist-valid-wrapper {
  background-color: #2E68EC;
  height: 100%;

  .msg {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    padding-top: 10%;
  }

  .jump {
    padding-top: 30px;
    color: #fff;
    font-size: 14px;
    text-align: center;

    span {
      cursor: pointer;
    }
  }
  .link {
    text-decoration: underline;
    cursor: pointer;
    color: #ec342e;
  }
}
</style>
