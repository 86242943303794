<template>
  <GlobalWindow :title="title" :visible.sync="visible" :confirm-working="isWorking" @confirm="confirm">
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入名称" v-trim />
      </el-form-item>
      <el-form-item label="地址" prop="url" required>
        <el-input v-model="form.url" placeholder="请输入地址" v-trim />
      </el-form-item>
      <el-form-item label="令牌" prop="token" required>
        <el-input v-model="form.token" placeholder="请输入令牌" v-trim />
      </el-form-item>
      <el-form-item label="状态" prop="status" required>
        <el-switch v-model="form.status" active-text="上线" inactive-text="下线" :active-value="1" :inactive-value="0">
        </el-switch>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaAgentBrowserWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        name: '',
        url: '',
        token: '',
        status: ''
      },
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入名称' }
        ],
        url: [
          { required: true, message: '请输入地址' }
        ],
        token: [
          { required: true, message: '请输入令牌' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/browser/agentBrowser',
      'field.id': 'id'
    })
  }
}
</script>
