<template>
  <TableLayout
    :permissions="['agentwebsiteparserrule:agentwebsiteparserrule:query']"
  >
    <!-- 搜索表单 -->
    <el-form
      ref="searchForm"
      slot="search-form"
      :model="searchForm"
      label-width="70px"
      inline
    >
      <el-form-item label="编号" prop="id">
        <el-input
          v-model="searchForm.id"
          placeholder="请输入编号"
          @keypress.enter.native="search"
        ></el-input>
      </el-form-item>
      <el-form-item label="生效状态" prop="ruleEnable">
        <el-input
          v-model="searchForm.ruleEnable"
          placeholder="请输入生效状态 0-生效 1-不生效"
          @keypress.enter.native="search"
        ></el-input>
      </el-form-item>
      <el-form-item label="规则组" prop="ruleGroup">
        <el-input
          v-model="searchForm.ruleGroup"
          placeholder="请输入规则生效组"
          @keypress.enter.native="search"
        ></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul
        class="toolbar"
        v-permissions="[
          'agentwebsiteparserrule:agentwebsiteparserrule:create',
          'agentwebsiteparserrule:agentwebsiteparserrule:delete',
        ]"
      >
        <li>
          <el-button
            type="primary"
            @click="
              $refs.operaAgentWebsiteParserRuleWindow.open('新建解析规则')
            "
            icon="el-icon-plus"
            v-permissions="[
              'agentwebsiteparserrule:agentwebsiteparserrule:create',
            ]"
            >新建</el-button
          >
        </li>
        <li>
          <el-button
            @click="deleteByIdInBatch"
            icon="el-icon-delete"
            v-permissions="[
              'agentwebsiteparserrule:agentwebsiteparserrule:delete',
            ]"
            >删除</el-button
          >
        </li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          prop="id"
          label="编号"
          min-width="100px"
        ></el-table-column>
        <!-- <el-table-column prop="ruleXml" label="解析规则" min-width="100px"></el-table-column> -->
        <el-table-column prop="ruleEnable" label="生效状态" min-width="100px">
          <template slot-scope="{ row }">
            <DictDataSelect
              v-model="row.ruleEnable"
              code="rule_enable"
              plac
              eholder="请输入生效请输入规则组状态"
              clearable
              disabled
            />
          </template>
        </el-table-column>
        <el-table-column prop="ruleGroup" label="规则组" min-width="100px">
          <template slot-scope="{ row }">
            <DictDataSelect
              v-model="row.ruleGroup"
              code="rule_group"
              plac
              eholder="请输入生效请输入规则组状态"
              clearable
              disabled
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="createBy"
          label=""
          min-width="100px"
        ></el-table-column>
        <el-table-column
          prop="createAt"
          label=""
          min-width="100px"
        ></el-table-column>
        <el-table-column
          v-if="
            containPermissions([
              'agentwebsiteparserrule:agentwebsiteparserrule:update',
              'agentwebsiteparserrule:agentwebsiteparserrule:delete',
            ])
          "
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              @click="
                $refs.operaAgentWebsiteParserRuleWindow.open(
                  '编辑解析规则',
                  row
                )
              "
              icon="el-icon-edit"
              v-permissions="[
                'agentwebsiteparserrule:agentwebsiteparserrule:update',
              ]"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="deleteById(row)"
              icon="el-icon-delete"
              v-permissions="[
                'agentwebsiteparserrule:agentwebsiteparserrule:delete',
              ]"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaAgentWebsiteParserRuleWindow
      ref="operaAgentWebsiteParserRuleWindow"
      @success="handlePageChange"
    />
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import DictDataSelect from '@/components/system/dict/DictDataSelect'
import OperaAgentWebsiteParserRuleWindow from './components/OperaAgentWebsiteParserRuleWindow'
export default {
  name: 'AgentWebsiteParserRule',
  extends: BaseTable,
  components: {
    TableLayout,
    Pagination,
    OperaAgentWebsiteParserRuleWindow,
    DictDataSelect
  },
  data () {
    return {
      // 搜索
      searchForm: {
        id: '',
        ruleEnable: '',
        ruleGroup: '',
        createBy: '',
        createAt: ''
      }
    }
  },
  created () {
    this.config({
      module: '解析规则',
      api: '/rules/agentWebsiteParserRule',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
