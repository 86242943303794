<template>
  <TableLayout :permissions="['appssearchlog:appssearchlog:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="应用编号" prop="appId">
        <el-input v-model="searchForm.appId" placeholder="请输入app 编号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="用户名" prop="username">
        <el-input v-model="searchForm.username" placeholder="请输入用户名" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table v-loading="isWorking.search" :data="tableData.list" stripe>
        <el-table-column prop="appId" label="应用编号" width="150px"></el-table-column>
        <el-table-column prop="username" label="用户名" min-width="80px">
          <template slot-scope="scope">
            <el-tag>
              {{ scope.row.username }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="queryType" label="查询类型" min-width="100px">
          <template slot-scope="scope">
            <el-tag>
              {{ scope.row.queryType }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="query" label="查询内容" min-width="100px"></el-table-column>
        <el-table-column prop="result" label="查询结果" min-width="100px">
          <template slot-scope="scope">
            <el-button size="mini" @click="showResult(scope.row.result)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="error" label="报错信息" min-width="100px"></el-table-column>
        <el-table-column prop="callTime" label="调用时间" min-width="100px"></el-table-column>
        <el-table-column prop="costTime" label="耗时" min-width="100px">
          <template slot-scope="scope">
            <el-tag>
              {{ scope.row.costTime }}ms
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <pagination @size-change="handleSizeChange" @current-change="handlePageChange" :pagination="tableData.pagination">
      </pagination>
    </template>
    <el-dialog title="查询结果" :visible.sync="result.dialogVisible" width="60%">
      <JsonViewer :expand-depth=5 copyable boxed sort :value="result.resultText" />
    </el-dialog>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import JsonViewer from 'vue-json-viewer'

export default {
  name: 'AppsSearchLog',
  extends: BaseTable,
  components: { TableLayout, Pagination, JsonViewer },
  data () {
    return {
      result: {
        dialogVisible: false,
        resultText: ''
      },
      // 搜索
      searchForm: {
        appId: '',
        username: ''
      }
    }
  },
  methods: {
    showResult (result) {
      this.result.resultText = JSON.parse(result)
      this.result.dialogVisible = true
    }
  },
  created () {
    this.config({
      module: '调用日志',
      api: '/apps/appsSearchLog',
      'field.id': 'id',
      'field.main': 'id',
      sorts: [{
        property: 'call_time',
        direction: 'DESC'
      }]
    })
    this.search()
  }
}
</script>
