<template>
  <div>
    <el-radio-group v-model="showModel">
      <el-radio-button label="json"></el-radio-button>
      <el-radio-button label="preview"></el-radio-button>
    </el-radio-group>
    <JsonViewer v-if="showModel === 'json'" :expand-depth=5 copyable :value="model" />
    <div v-else class="detail-wrapper space-y-2">
      <el-descriptions size="medium" :column="2" border>
        <el-descriptions-item label="date">{{ model.date }}</el-descriptions-item>
        <el-descriptions-item label="successful">{{ model.successful }}</el-descriptions-item>
        <el-descriptions-item label="queued">{{ model.queued }}</el-descriptions-item>
        <el-descriptions-item label="rejected">{{ model.rejected }}</el-descriptions-item>
        <el-descriptions-item label="unhealthy">{{ model.unhealthy }}</el-descriptions-item>
        <el-descriptions-item label="memory">{{ model.memory }}</el-descriptions-item>
        <el-descriptions-item label="cpu">{{ model.cpu }}</el-descriptions-item>
        <el-descriptions-item label="timedout">{{ model.timedout }}</el-descriptions-item>
        <el-descriptions-item label="totalTime">{{ model.totalTime }}</el-descriptions-item>
        <el-descriptions-item label="meanTime">{{ model.meanTime }}</el-descriptions-item>
        <el-descriptions-item label="maxTime">{{ model.maxTime }}</el-descriptions-item>
        <el-descriptions-item label="minTime">{{ model.minTime }}</el-descriptions-item>
        <el-descriptions-item label="maxConcurrent">{{ model.maxConcurrent }}</el-descriptions-item>
        <el-descriptions-item label="sessionTimes">{{ model.sessionTimes }}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import { pressure } from '@/api/browser/agentBrowser.js'
import JsonViewer from 'vue-json-viewer'

export default {
  name: 'PressureDetail',
  components: { JsonViewer },
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      showModel: 'json',
      // configText: '',
      model: {
        date: 0,
        successful: 0,
        queued: 0,
        rejected: 0,
        unhealthy: 0,
        memory: 0,
        cpu: 0,
        timedout: 0,
        totalTime: 0,
        meanTime: 0,
        maxTime: 0,
        minTime: 0,
        maxConcurrent: 0,
        sessionTimes: [0]
      }
    }
  },
  methods: {
    async load (id) {
      try {
        const res = await pressure(id)
        // this.configText = res
        this.model = res.pressure
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-wrapper {
  padding: 20px 0;
}
</style>
