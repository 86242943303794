import request from '@/utils/request'

export function loadEmailConfig () {
  return request.get('/emailConfig/load')
}

export function saveEmailConfig (data) {
  return request.post('/emailConfig/save', data)
}

export function loadSysConfig () {
  return request.get('/sysConfig/load')
}

export function saveSysConfig (data) {
  return request.post('/sysConfig/save', data)
}
