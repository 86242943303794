<template>
  <div>
    <el-radio-group v-model="showModel">
      <el-radio-button label="json"></el-radio-button>
      <el-radio-button label="preview"></el-radio-button>
    </el-radio-group>
    <el-button :loading="closeing" @click="handleKillAll" style="margin-left: 10px;" type="danger">关闭会话</el-button>
    <JsonViewer v-if="showModel === 'json'" :expand-depth=5 copyable :value="model" />
    <div v-else class="detail-wrapper space-y-2">
      <el-collapse>
        <el-collapse-item v-for="(item,index) in model" :key="item.id" :name="item.id">
          <template slot="title">
            <span><el-tag>{{ index }}</el-tag> {{ item.url }}</span>
          </template>
          <el-descriptions size="medium" :column="2" border>
            <el-descriptions-item label="title">{{ item.title }}</el-descriptions-item>
            <el-descriptions-item label="id">{{ item.id }}</el-descriptions-item>
            <el-descriptions-item label="browserId">{{ item.browserId }}</el-descriptions-item>
            <el-descriptions-item label="browserWSEndpoint">{{ item.browserWSEndpoint }}</el-descriptions-item>
            <el-descriptions-item label="description">{{ item.description }}</el-descriptions-item>
            <el-descriptions-item label="devtoolsFrontendUrl">{{ item.devtoolsFrontendUrl }}</el-descriptions-item>
            <el-descriptions-item label="type">
              <el-tag>{{ item.type }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="webSocketDebuggerUrl">{{ item.webSocketDebuggerUrl }}</el-descriptions-item>
            <el-descriptions-item label="port">
              <el-tag disable-transitions>{{ item.port }}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="trackingId">{{ item.trackingId }}</el-descriptions-item>
          </el-descriptions>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import { sessions, killALl } from '@/api/browser/agentBrowser.js'
import JsonViewer from 'vue-json-viewer'

export default {
  name: 'SessionDetail',
  components: { JsonViewer },
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      closeing: false,
      showModel: 'json',
      browserId: '',
      // configText: '',
      model: [
        {
          description: '',
          devtoolsFrontendUrl: '',
          id: '',
          title: '',
          type: '',
          url: '',
          webSocketDebuggerUrl: '',
          port: '',
          trackingId: '',
          browserWSEndpoint: '',
          browserId: ''
        }
      ]
    }
  },
  methods: {
    handleKillAll () {
      this.closeing = true
      killALl(this.browserId)
        .then(() => {
          this.$message.success('关闭成功')
          this.load(this.browserId)
        })
        .catch(() => {
          this.$message.error('关闭失败')
        })
        .finally(() => {
          this.closeing = false
        })
    },
    async load (id) {
      try {
        this.browserId = id
        const res = await sessions(id)
        // this.configText = res
        this.model = res
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-wrapper {
  padding: 20px 0;
}
</style>
