<template>
  <router-view/>
</template>

<script>
import { loadSysConfig } from '@/api/system/config'
export default {
  methods: {
    async loadSysConf () {
      const sysConf = await loadSysConfig()
      document.title = sysConf.sysName
    }
  },
  mounted () {
    this.loadSysConf()
  }
}
</script>

<style lang="scss">
// 引入全局样式
@import "assets/style/style";
// 引入系统内置图标
@import "assets/icons/system/index";
// 引入自定义图标
@import "assets/icons/ext/index";
</style>
