<template>
  <div class="config-wrapper">
    <!-- 头部 -->
    <div v-if="withBreadcrumb" class="table-header">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="path in paths" :key="path">{{ path }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="padding: 0 16px;">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="系统配置" name="system">
          <SysConfig/>
        </el-tab-pane>
        <el-tab-pane label="邮箱配置" name="email">
          <EmailConfig/>
        </el-tab-pane>
        <!-- <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>
        <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import EmailConfig from './email/index.vue'
import SysConfig from './sysconf/index.vue'
export default {
  name: 'SystemConfig',
  components: {
    EmailConfig, SysConfig
  },
  data () {
    return {
      activeName: 'system'
    }
  },
  props: {
    // 是否展示头部面包屑
    withBreadcrumb: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleClick () { }
  },
  computed: {
    paths () {
      return this.$route.meta.paths
    }
  }
}
</script>

<style lang="scss" scoped>
.config-wrapper {
  padding: 0;

  .table-header {
    overflow: hidden;
    padding: 12px 16px;
    flex-shrink: 0;

    // 页面路径
    .el-breadcrumb {
      .el-breadcrumb__item {
        .el-breadcrumb__inner {
          color: #ABB2BE;
          font-size: 12px;
        }

        &:last-of-type .el-breadcrumb__inner {
          color: #606263;
          font-size: 14px;
        }
      }
    }
  }
}</style>
