<template>
  <div class="wrap">
    <div class="introduce">
      <div class="slogn-wrapper">
        <h1 class="bfont">{{sysNeme}}</h1>
        <h4 class="bfont">Efficient, convenient, and comprehensive API service for search engine data retrieval.</h4>
      </div>
    </div>
    <div class="login">
      <div class="login-header"></div>
      <!-- <h1 class="bfont">系统注册</h1> -->
      <h1 class="bfont">Sign up</h1>
      <div class="info-input">
        <el-input v-model="username" placeholder="Username" prefix-icon="el-icon-user-solid" maxlength="50" v-trim />
        <el-input v-model="email" placeholder="Email" prefix-icon="el-icon-message" maxlength="50" v-trim />
        <el-input v-model="password" placeholder="Password" type="password" prefix-icon="eva-icon-password" maxlength="30"
          show-password />
        <el-input v-model="confirmPassword" placeholder="Confirm Password" type="password" prefix-icon="eva-icon-password"
          maxlength="30" show-password />
        <div class="captcha-input">
          <el-input v-model="captcha.value" placeholder="Code" prefix-icon="eva-icon-shield" maxlength="4"
            @keypress.enter.native="login" />
          <img v-if="!captcha.loading" :src="captcha.uri" @click="refreshCaptcha" />
          <span v-else><i class="el-icon-loading"></i></span>
        </div>
      </div>
      <el-button :loading="loading" @click="handleRegist"><span class="bfont">Sign up</span></el-button>
      <div style="padding-top: 20px; text-align: left;">
        <div style="cursor: pointer; text-decoration: underline" @click="handleToMain"><span class="bfont"> <i class="el-icon-back"></i> Back</span></div>
      </div>
    </div>

    <div>
      <el-dialog :visible.sync="registConfirm.show" width="500px" @close="handleClose">
        <div class="confim-wrapper">
          <div class="icon">
            <div><img src="@/assets/icons/ext/star.svg"></div>
          </div>
          <div class="content">
            The registration email has been sent, please go check your inbox to confirm it
          </div>
          <div class="footer"></div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { getCaptcha, signUpByEmail } from '@/api/system/common'
import { loadSysConfig } from '@/api/system/config'

export default {
  name: 'Login',
  data () {
    return {
      sysNeme: '',
      loading: false,
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      // 验证码
      captcha: {
        loading: false,
        value: '',
        uuid: '',
        uri: ''
      },
      registConfirm: {
        show: false
      }
    }
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    async loadSysConf () {
      const sysConf = await loadSysConfig()
      this.sysNeme = sysConf.sysName || 'AgentBrowser'
      document.title = this.sysNeme
    },
    handleClose () {
      this.$router.push({ name: 'login' })
    },
    /**
     * 登录
     */
    handleRegist () {
      if (this.loading) {
        return
      }
      if (!this.__check()) {
        return
      }
      this.loading = true
      signUpByEmail({
        username: this.username.trim(),
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword,
        code: this.captcha.value.trim(),
        uuid: this.captcha.uuid
      })
        .then(() => {
          // window.location.href = process.env.VUE_APP_CONTEXT_PATH
          this.registConfirm.show = true
        })
        .catch((e) => {
          this.refreshCaptcha()
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * 刷新验证码
     */
    refreshCaptcha () {
      this.captcha.loading = true
      getCaptcha()
        .then((data) => {
          this.captcha.uri = data.image
          this.captcha.uuid = data.uuid
        })
        .catch((e) => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          setTimeout(() => {
            this.captcha.loading = false
          }, 150)
        })
    },
    /**
     * 登录前验证
     *
     * @returns {boolean}
     * @private
     */
    __check () {
      if (this.username.trim() === '') {
        this.$tip.error('请输入用户名')
        return false
      }
      if (this.email.trim() === '') {
        this.$tip.error('请输入邮箱')
        return false
      }
      if (this.password === '') {
        this.$tip.error('请输入密码')
        return false
      }
      if (this.confirmPassword === '') {
        this.$tip.error('请确认密码')
        return false
      }
      if (this.confirmPassword !== this.password) {
        this.$tip.error('密码不一致,请再次确认')
        return false
      }
      if (this.captcha.value.trim() === '') {
        this.$tip.error('请输入图片验证码')
        return false
      }
      return true
    },
    handleToMain () {
      this.$router.push('/main')
    }
  },
  created () {
    this.refreshCaptcha()
    this.loadSysConf()
  }
}
</script>

<style lang="scss">
.confim-wrapper {
  .icon {
    text-align: center;
    img {
      width: 120px;
    }
  }
  .content{
    text-align: center;
    // font-size: 16px;
  }
  .footer {
    height: 50px;
  }
}
.wrap {
  .introduce {
    color: #fff;
    display: inline-block;
    width: calc(100% - 560px);
    height: calc(100% - 10px);

    @media screen and (max-width: 1060px) {
      display: none;
    }

    min-width: 500px;
    position: relative;

    .slogn-wrapper {
      height: 100px;
      position: absolute;
      top: 50%;
      transform: translate(100px, -50%);

      h1 {
        font-size: 80px;
      }
      h4 {
        font-size: 24px;
        font-weight: 300;
        margin-top: 20px;
      }
    }
  }

  .login {
    display: inline-block;

    @media screen and (max-width: 1060px) {
      width: 100% !important;
    }

    .login-header {
      height: 100px;
    }
  }

  .sosal-btn {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
    }
  }
}
</style>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
$input-gap: 30px;

.wrap {
  // display: flex;
  width: 100%;
  height: 100vh;
  // background-image: url("../assets/images/login.jpg");
  background-repeat: no-repeat;
  background-size: auto 180%;
  background-clip: content-box;
  background-position: center;
  background-image: linear-gradient(to right, #d299c2 0%, #fef9d7 100%);
  s // 左边介绍

  .introduce {
    display: inline-block;
    vertical-align: top;
    padding-left: 10%;
    width: 100%;
    height: 100% !important;
    box-sizing: border-box;
    color: #fff;
    background: rgba(0, 0, 0, 0.4);

    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    h2 {
      font-size: 34px;
      font-style: italic;
      font-weight: 900;
      margin-top: 50px;
    }

    h3 {
      font-size: 49px;
      font-weight: 300;
      margin: 25px 0;
    }
  }

  // 右边登录
  .login {
    display: inline-block;
    vertical-align: top;

    height: 100%;
    width: 38%;
    width: 560px;
    // flex-shrink: 0;
    text-align: center;
    background: #fff;
    padding: 0 80px;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    box-sizing: border-box;

    h1 {
      font-size: 28px;
      font-weight: 500;
    }

    .info-input {
      margin-top: $input-gap;
      margin-bottom: 60px;

      /deep/ .el-input {
        margin-top: 30px;

        .el-input__inner {
          height: 50px;
          background: #f9f9f9;
          border: 1px solid transparent;

          &:focus {
            border: 1px solid $primary-color;
          }
        }
      }
    }

    // 验证码输入
    .captcha-input {
      display: flex;
      margin-top: $input-gap;
      height: 50px;

      .el-input {
        width: 100%;
        margin-top: 0;
      }

      span,
      img {
        // width: 45%;
        width: 180px;
        height: 100%;
        flex-shrink: 0;
        margin-left: 16px;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f7f7f7;
        border-radius: 8px;
      }
    }

    .el-button {
      height: 50px;
      width: 100%;
      color: #fff;
      font-size: 16px;
      background: linear-gradient(130deg,
          $primary-color + 20 0%,
          $primary-color - 20 100%);
    }
  }
}
</style>
