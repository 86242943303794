<template>
  <div>
    <el-radio-group v-model="showModel">
      <el-radio-button label="json"></el-radio-button>
      <el-radio-button label="preview"></el-radio-button>
    </el-radio-group>
    <JsonViewer v-if="showModel === 'json'" :expand-depth=5 copyable :value="model" />
    <div v-else class="detail-wrapper">
      <el-descriptions size="medium" border>
        <el-descriptions-item label="允许文件协议">{{model.allowFileProtocol}}</el-descriptions-item>
        <el-descriptions-item label="Chrome刷新时间">{{model.chromeRefreshTime}}</el-descriptions-item>
        <el-descriptions-item label="连接超时时间">{{model.connectionTimeout}}</el-descriptions-item>
        <el-descriptions-item label="禁用功能">
          <el-tag v-for="item in model.disabledFeatures" :key="item" closable disable-transitions>{{item}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="开启APIGet">{{model.enableAPIGet}}</el-descriptions-item>
        <el-descriptions-item label="开启跨域">{{model.enableCors}}</el-descriptions-item>
        <el-descriptions-item label="错误警报URL">{{model.errorAlertURL}}</el-descriptions-item>
        <el-descriptions-item label="健康检查失败时退出">{{model.exitOnHealthFailure}}</el-descriptions-item>
        <el-descriptions-item label="函数内建模块">
          <el-tag v-for="item in model.functionBuiltIns" :key="item" disable-transitions>{{item}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="为函数启用隐身模式">{{model.functionEnableIncognitoMode}}</el-descriptions-item>
        <el-descriptions-item label="函数外部模块">
          <el-tag v-for="item in model.functionExternals" :key="item" disable-transitions>{{item}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="健康检查失败URL">{{model.healthFailureURL}}</el-descriptions-item>
        <el-descriptions-item label="会话检查失败URL">{{model.sessionCheckFailURL}}</el-descriptions-item>
        <el-descriptions-item label="保持连接存活">{{model.keepAlive}}</el-descriptions-item>
        <el-descriptions-item label="最大CPU使用率">{{model.maxCPU}}</el-descriptions-item>
        <el-descriptions-item label="最大并发会话数">{{model.maxConcurrentSessions}}</el-descriptions-item>
        <el-descriptions-item label="最大内存使用量">{{model.maxMemory}}</el-descriptions-item>
        <el-descriptions-item label="最大队列长度">{{model.maxQueueLength}}</el-descriptions-item>
        <el-descriptions-item label="指标JSON路径">{{model.metricsJSONPath}}</el-descriptions-item>
        <el-descriptions-item label="端口号">{{model.port}}</el-descriptions-item>
        <el-descriptions-item label="预先启动Chrome">{{model.prebootChrome}}</el-descriptions-item>
        <el-descriptions-item label="排队警报URL">{{model.queuedAlertURL}}</el-descriptions-item>
        <el-descriptions-item label="拒绝请求警报URL">{{model.rejectAlertURL}}</el-descriptions-item>
        <el-descriptions-item label="单次运行">{{model.singleRun}}</el-descriptions-item>
        <el-descriptions-item label="超时警报URL">{{model.timeoutAlertURL}}</el-descriptions-item>
        <el-descriptions-item label="Token">{{model.token}}</el-descriptions-item>
        <el-descriptions-item label="工作目录">{{model.workspaceDir}}</el-descriptions-item>
        <el-descriptions-item label="Socket行为">{{model.socketBehavior}}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import { configs } from '@/api/browser/agentBrowser.js'
import JsonViewer from 'vue-json-viewer'

export default {
  name: 'ConfigDetail',
  components: { JsonViewer },
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      showModel: 'json',
      // configText: '',
      model: {
        allowFileProtocol: '',
        chromeRefreshTime: '',
        connectionTimeout: '',
        disabledFeatures: [],
        enableAPIGet: '',
        enableCors: '',
        errorAlertURL: '',
        exitOnHealthFailure: '',
        functionBuiltIns: [],
        functionEnableIncognitoMode: '',
        functionExternals: [],
        healthFailureURL: '',
        sessionCheckFailURL: '',
        keepAlive: '',
        maxCPU: '',
        maxConcurrentSessions: '',
        maxMemory: '',
        maxQueueLength: '',
        metricsJSONPath: '',
        port: '',
        prebootChrome: '',
        queuedAlertURL: '',
        rejectAlertURL: '',
        singleRun: '',
        timeoutAlertURL: '',
        token: '',
        workspaceDir: '',
        socketBehavior: ''
      }
    }
  },
  methods: {
    async load (id) {
      try {
        const res = await configs(id)
        // this.configText = res
        this.model = res
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-wrapper {
  padding: 20px 0;
}
</style>
