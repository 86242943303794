<template>
  <div v-loading="loading">
    <el-form label-position="right" label-width="100px" :model="formData">
      <el-form-item label="系统名称">
        <el-input v-model="formData.sysName"></el-input>
      </el-form-item>
      <el-form-item label="系统地址">
        <el-input v-model="formData.url"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSave">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { loadSysConfig, saveSysConfig } from '@/api/system/config'

export default {
  name: 'SysConfig',
  data () {
    return {
      loading: false,
      formData: {
        sysName: 'smtps',
        url: ''
      }
    }
  },
  methods: {
    __check () {
      if (this.formData.sysName.trim() === '') {
        this.$tip.error('请输入协议')
        return false
      }
      if (this.formData.url.trim() === '') {
        this.$tip.error('请输入SMTP 主机')
        return false
      }
      return true
    },
    async handleSave () {
      if (!this.__check()) {
        return
      }
      this.loading = true
      try {
        await saveSysConfig(this.formData)
        this.$tip.success('保存成功')
      } catch (error) {
        console.error(error)
        this.$tip.apiFailed(error)
      } finally {
        this.loading = false
      }
    },
    async loadConfig () {
      this.loading = true
      try {
        const data = await loadSysConfig()
        this.formData = {
          sysName: data.sysName,
          url: data.url
        }
      } catch (error) {
        console.error(error)
        this.$tip.apiFailed(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.loadConfig()
  }
}
</script>

<style lang="scss" scoped></style>
